import React, { useEffect, useState } from "react";
import "../styles/home.css";
import homeBgLg from "../assets/images/pb-web-bg-lg.jpg";
import homeBgSm from "../assets/images/pb-web-bg-sm.jpg";
import home_swirl from "../assets/svgs/home-swirl.svg";
import scroll_Down from "../assets/svgs/scroll-down-ar.svg";
import gitex from "../assets/svgs/gitex.svg";
// import northstar from "../assets/svgs/northstar.svg";
import discover from "../assets/svgs/discover.svg";
import qrcode from "../assets/svgs/qrcode.svg";
import downloadbtn from "../assets/svgs/downloadbtn.svg";
import { Link } from "react-scroll";
import AnimateEachLetter from "./animation/AnimateEachLetter";

const Home = () => {
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobileView(window.innerWidth <= 670);
    };

    checkScreenWidth();

    // Add event listener to update isMobileView state when window is resized
    window.addEventListener("resize", checkScreenWidth);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);
  return (
    <section
      id="home"
      className="tablet:h-[100svh] w-[100vw] bg-[#092351] flex justify-center items-end mobile:items-center pb-[75px] tablet:pb-0 relative"
      style={{
        backgroundImage: `url(${isMobileView ? homeBgSm : homeBgLg})`,
        backgroundSize: "cover",
        backgroundPositionX: "right",
      }}
    >
      {/* <div className="bg-gray-800- w-[100%]  snap-in- text-center text-[74px] mobile:text-[102px] tablet:text-[130px] laptop:text-[158px] leading-none text-[#FFFFFF] flex justify-center"> */}

      <div className="tablet:flex tablet:flex-row-reverse flex flex-col-reverse tablet:w-full laptop:mt-28 tablet:mt-16 mobile:mt-[30em] mt-[15em]">
        <div className="hidden  mt-36 tablet:w-[30%] tablet:flex tablet:flex-col tablet:items-baseline tablet:pr-[10em]">
          <img className="qr-sub-section-animation " src={gitex} alt="" />
          {/* <img
            className="qr-sub-section-animation tablet:w-auto w-[50%]"
            src={northstar}
            alt=""
          />
          <img
            className="qr-sub-section-animation tablet:w-auto w-[50%]"
            src={discover}
            alt=""
          />
          <img
            className="qr-sub-section-animation !mt-[2.5em] tablet:w-auto w-[20%]"
            src={qrcode}
            alt=""
          /> */}
        </div>
        <div className="mobile tablet:hidden flex flex-col items-end !mr-6  !mt-16 qr-sub-section-animation">
          {/* <img className=" w-[83%]" src={northstar} alt="" /> */}
          <div className="flex items-center  w-[83%] ">
            <div dir="" className=" w-[50%] ">
              <img src={qrcode} alt="" />
            </div>
            <div dir="rtl" className=" w-[50%] ">
              {" "}
              <img src={discover} alt="" />
              <img
                className="mt-[23px] cursor-pointer"
                src={downloadbtn}
                alt=""
                onClick={() =>
                  window.open(
                    "https://pbcdn.planetbeyondapps.com/resources/Planetbeyond/planetbeyond.pdf",
                    "_blank"
                  )
                }
              />
            </div>
          </div>
        </div>

        <div className="font-74-to-158 text-center leading-none text-[#FFFFFF] flex  tablet:w-[70%] ">
          <div className="tablet:mr-28 tablet:space-y-5">
            {/* <div className="uppercase text-left font-[300] pr-0 tablet:pr-[116px] laptop:pr-[233px] desktop:pr-[350px]">
            Planet
          </div> */}
            <div className="flex justify-start tablet:pl-[1.0em]">
              <AnimateEachLetter
                text={
                  <div className="mb-5 english-font uppercase text-right transform translate-x-10 font-[300]">
                    Planet
                    {/* بلانىت */}
                  </div>
                }
                className={
                  "uppercase text-left transform translate-x-10  font-[300]"
                }
                animationClass={"hero-section-animation"}
                animationLetterDelay={0.01}
              />
            </div>
            <div className="flex flex-row-reverse items-center pl-0 tablet:pl-[100px] laptop:pl-[200px] desktop:pl-[300px] gap-3  tablet:mr-20">
              <img
                className="hero-sub-section-animation flip-x w-[36px] tablet:w-[43px] laptop:w-[50px] desktop:w-[57px] laptop:mr-[10px] laptop:mt-7 -mt-6"
                src={home_swirl}
                alt={"PlanetBeyond"}
              />
              <div className="space-y-4">
                {/* <div className="uppercase font-black text-left ">Beyond</div> */}
                <div className="text-right translate-y-2">
                  <AnimateEachLetter
                    text={
                      <div className="mb-5 english-font uppercase font-black text-left">
                        Beyond {/*"بيوند"*/}
                      </div>
                    }
                    className={"uppercase font-black text-left"}
                    animationClass={"hero-section-animation"}
                    baseAnimationDelay={0.06}
                    animationLetterDelay={0.01}
                  />
                </div>
                <div
                  dir="rtl"
                  className="hero-sub-section-animation pt-5 w-[329px] mobile:w-[453px] text-right laptop:w-[577px] desktop:w-[710px] text-[16px] mobile:text-[18.67px] tablet:text-[21.33px] laptop:text-[24px] font-light pl-2 mobile:leading-[34.82px] leading-[23.22px]"
                >
                  قوة تعطي معنىً جديدًا لحدود الإمكانيات. مهمتنا في PlanetBeyond
                  بسيطة ولكنها بالغة الأثر: وهي إتاحة الفرصة للشركات من أجل
                  تحقيق النجاح في النظام العالمي الجديد. انطلق في هذه الرحلة
                  معنا
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden tablet:block absolute bottom-6 laptop:bottom-12">
        <div className="relative">
          <div className="absolute  w-[100%] h-[100%] scroll-btn border-2 rounded-full border-t-4 border-y-[#092351] pointer-events-none" />
          <Link
            to={"mobileOperators"}
            spy={true}
            smooth={false}
            offset={-70}
            duration={500}
          >
            <div className="cursor-pointer w-[120px] desktop:w-[148px] aspect-square">
              <div className="h-full w-full flex justify-center items-center">
                <img
                  className="size-[60%]"
                  src={scroll_Down}
                  alt={"Scroll down"}
                />
              </div>
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Home;
